import React, {
  useEffect,
  useState,
} from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'

import 'simplebar-react/dist/simplebar.min.css'

import logo from '../images/trucabon-logo.png'
import logoSmall from '../images/trucabon-logo.png'

import { ROUTE_PATHS } from '../library/helper'

const types = {
  activatable: 'activatable',
  expandable: 'expandable',
  showable: 'showable'
}

const sidebarData = {
  [ROUTE_PATHS.dashboard]: [['sidebarDashboard', types.activatable]],
  [ROUTE_PATHS.emission]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeOneTwo', types.activatable],
  ],
  [ROUTE_PATHS.scope3category1]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory1', types.activatable],
  ],
  [ROUTE_PATHS.scope3category2]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory2', types.activatable],
  ],
  [ROUTE_PATHS.scope3category3]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory3', types.activatable],
  ],
  [ROUTE_PATHS.scope3category4]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory4', types.activatable],
  ],
  [ROUTE_PATHS.scope3category5]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory5', types.activatable],
  ],
  [ROUTE_PATHS.scope3category6]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory6', types.activatable],
  ],
  [ROUTE_PATHS.scope3category7]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory7', types.activatable],
  ],
  [ROUTE_PATHS.scope3category8]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory8', types.activatable],
  ],
  [ROUTE_PATHS.scope3category9]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory9', types.activatable],
  ],
  [ROUTE_PATHS.scope3category10]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory10', types.activatable],
  ],
  [ROUTE_PATHS.scope3category11]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory11', types.activatable],
  ],
  [ROUTE_PATHS.scope3category12]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory12', types.activatable],
  ],
  [ROUTE_PATHS.scope3category13]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory13', types.activatable],
  ],
  [ROUTE_PATHS.scope3category14]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory14', types.activatable],
  ],
  [ROUTE_PATHS.scope3category15]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureEmission', types.expandable],
    ['sidebarMeasureEmissionChildren', types.showable],
    ['sidebarMeasureEmissionScopeThree', types.expandable],
    ['sidebarMeasureEmissionScopeThreeChildren', types.showable],
    ['sidebarMeasureEmissionScopeThreeCategory15', types.activatable],
  ],
  [ROUTE_PATHS.revenue]: [
    ['sidebarMeasure', types.expandable],
    ['sidebarMeasureChildren', types.showable],
    ['sidebarMeasureRevenue', types.activatable],
  ],
  [ROUTE_PATHS.reduction]: [
    ['sidebarAction', types.expandable],
    ['sidebarActionChildren', types.showable],
    ['sidebarActionReduction', types.activatable],
  ],
  [ROUTE_PATHS.removal]: [
    ['sidebarAction', types.expandable],
    ['sidebarActionChildren', types.showable],
    ['sidebarActionRemoval', types.activatable],
  ],
  [ROUTE_PATHS.scenario]: [
    ['sidebarAnalytic', types.expandable],
    ['sidebarAnalyticChildren', types.showable],
    ['sidebarAnalyticScenario', types.activatable],
  ],
  [ROUTE_PATHS.analyticsSBTi]: [
    ['sidebarAnalytic', types.expandable],
    ['sidebarAnalyticChildren', types.showable],
    ['sidebarAnalyticScenarioSBTi', types.activatable],
  ],
  [ROUTE_PATHS.analyticsCarbonIntensity]: [
    ['sidebarAnalytic', types.expandable],
    ['sidebarAnalyticChildren', types.showable],
    ['sidebarAnalyticCarbonIntensity', types.activatable],
  ],
  [ROUTE_PATHS.analyticsCompletion]: [
    ['sidebarAnalytic', types.expandable],
    ['sidebarAnalyticChildren', types.showable],
    ['sidebarAnalyticCompletion', types.activatable],
  ],
  [ROUTE_PATHS.analyticsLocation]: [
    ['sidebarAnalytic', types.expandable],
    ['sidebarAnalyticChildren', types.showable],
    ['sidebarAnalyticLocation', types.activatable],
  ],
  [ROUTE_PATHS.analyticsLocationSummary]: [
    ['sidebarAnalytic', types.expandable],
    ['sidebarAnalyticChildren', types.showable],
    ['sidebarAnalyticLocationSummary', types.activatable],
  ],
  [ROUTE_PATHS.reportIso]: [
    ['sidebarReport', types.expandable],
    ['sidebarReportChildren', types.showable],
    ['sidebarReportISO', types.activatable],
  ],
  [ROUTE_PATHS.reportGhg]: [
    ['sidebarReport', types.expandable],
    ['sidebarReportChildren', types.showable],
    ['sidebarReportGHG', types.activatable],
  ],
  [ROUTE_PATHS.reportGri]: [
    ['sidebarReport', types.expandable],
    ['sidebarReportChildren', types.showable],
    ['sidebarReportGRI', types.activatable],
  ],
  [ROUTE_PATHS.reportSummary]: [
    ['sidebarReport', types.expandable],
    ['sidebarReportChildren', types.showable],
    ['sidebarReportSummary', types.activatable],
  ],
  [ROUTE_PATHS.division]: [
    ['sidebarSetting', types.expandable],
    ['sidebarSettingChildren', types.showable],
    ['sidebarSettingAdvanced', types.expandable],
    ['sidebarSettingAdvancedChildren', types.showable],
    ['sidebarSettingAdvancedDivision', types.activatable],
  ],
  [ROUTE_PATHS.company]: [
    ['sidebarSetting', types.expandable],
    ['sidebarSettingChildren', types.showable],
    ['sidebarSettingAdvanced', types.expandable],
    ['sidebarSettingAdvancedChildren', types.showable],
    ['sidebarSettingAdvancedCompany', types.activatable],
  ],
  [ROUTE_PATHS.location]: [
    ['sidebarSetting', types.expandable],
    ['sidebarSettingChildren', types.showable],
    ['sidebarSettingAdvanced', types.expandable],
    ['sidebarSettingAdvancedChildren', types.showable],
    ['sidebarSettingAdvancedLocation', types.activatable],
  ],
  [ROUTE_PATHS.user]: [
    ['sidebarSetting', types.expandable],
    ['sidebarSettingChildren', types.showable],
    ['sidebarSettingAdvanced', types.expandable],
    ['sidebarSettingAdvancedChildren', types.showable],
    ['sidebarSettingAdvancedUser', types.activatable],
  ],
  [ROUTE_PATHS.settingRevenue]: [
    ['sidebarSetting', types.expandable],
    ['sidebarSettingChildren', types.showable],
    ['sidebarSettingAdvanced', types.expandable],
    ['sidebarSettingAdvancedChildren', types.showable],
    ['sidebarSettingAdvancedRevenue', types.activatable],
  ],
  '/setting/advanced/emission-sources': [
    ['sidebarSetting', types.expandable],
    ['sidebarSettingChildren', types.showable],
    ['sidebarSettingAdvanced', types.expandable],
    ['sidebarSettingAdvancedChildren', types.showable],
    ['sidebarSettingAdvancedEmissionSources', types.activatable],
  ],
  [ROUTE_PATHS.setting]: [
    ['sidebarSetting', types.expandable],
    ['sidebarSettingChildren', types.showable],
    ['sidebarSettingGeneral', types.activatable],
  ],
  [ROUTE_PATHS.settingScenario]: [
    ['sidebarSetting', types.expandable],
    ['sidebarSettingChildren', types.showable],
    ['sidebarSettingScenario', types.activatable],
  ],
  [ROUTE_PATHS.settingCarbonIntensity]: [
    ['sidebarSetting', types.expandable],
    ['sidebarSettingChildren', types.showable],
    ['sidebarSettingCarbonIntensity', types.activatable],
  ],
}

// Main Menu that does not have parents
const ActivableWithIcon = ({
  to,
  id,
  icon,
  label,
}) => {
  return (
    <li className="nav-item">
      <Link
        className='nav-link menu-link'
        to={to}
        id={id}
      >
        <i className={icon}></i>
        <span>{label}</span>
      </Link>
    </li>
  )
}

// Menu that has parents
const Activable = ({
  to,
  id,
  label,
  detail,
}) => {
  return (
    <li className="nav-item" title={detail}>
      <Link
        to={to}
        className='nav-link'
        id={id}
      >
        {label}
      </Link>
    </li>
  )
}

// Expandable and ExpandableWithIcon's direct child
const Showable = ({
  parentId,
  id,
  children,
  custom,
}) => {
  return (
    <div
      className="collapse menu-dropdown"
      style={{ width: custom }}
      data-bs-parent={parentId}
      id={id}
    >
      <ul className="nav nav-sm flex-column">
        {children}
      </ul>
    </div>
  )
}

// Expandable menu that has parents
const Expandable = ({
  childToToggle,
  parentId,
  id,
  label,
  children,
}) => {
  return (
    <li className="nav-item">
      <a
        data-bs-toggle="collapse"
        data-bs-target={`#${childToToggle}`}
        className="nav-link"
        href={`#${childToToggle}`}
        role="button"
        aria-expanded="false"
        aria-controls={childToToggle}
        data-bs-parent={parentId}
        id={id}
      >
        {label}
      </a>
      {children}
    </li>
  )
}

// Expandable menu that does not have parents
const ExpandableWithIcon = ({
  childToToggle,
  id,
  icon,
  label,
  children,
}) => {
  return (
    <li className="nav-item">
      <a
        data-bs-toggle="collapse"
        data-bs-target={`#${childToToggle}`}
        className="nav-link menu-link"
        href={`#${childToToggle}`}
        role="button"
        aria-expanded="false"
        aria-controls={childToToggle}
        id={id}
      >
        <i className={icon}></i>
        <span>
          {label}
        </span>
      </a>
      {children}
    </li>
  )
}

const SoonMenu = ({
  href,
  id,
  label
}) => {
  const { t } = useTranslation()
  return (
    <li className="nav-item">
      <a
        href={href}
        className="nav-link"
        id={id}
      >
        {label}&nbsp;<sup style={{ color: "#c7ece7" }}>{t('menu.soon')}</sup>
      </a>
    </li>
  )
}

const MenuSection = () => {
  const { t } = useTranslation()
  const account = useSelector((state) => state.account.value)
  const location = useLocation()
  const [parentPath, setParentPath] = useState('/')

  useEffect(() => {
    if (location.pathname) {
      if (
        location.pathname === ROUTE_PATHS.analyticsLocation ||
        location.pathname === ROUTE_PATHS.analyticsSBTi ||
        location.pathname === ROUTE_PATHS.analyticsLocationSummary ||
        location.pathname === ROUTE_PATHS.analyticsCarbonIntensity ||
        location.pathname === ROUTE_PATHS.reportIso ||
        location.pathname === ROUTE_PATHS.reportGhg ||
        location.pathname === ROUTE_PATHS.reportGri ||
        location.pathname === ROUTE_PATHS.reportSummary ||
        location.pathname === ROUTE_PATHS.settingScenario ||
        location.pathname === ROUTE_PATHS.settingCarbonIntensity ||
        location.pathname === ROUTE_PATHS.settingRevenue ||
        location.pathname === ROUTE_PATHS.division ||
        location.pathname === ROUTE_PATHS.company ||
        location.pathname === ROUTE_PATHS.location ||
        location.pathname === ROUTE_PATHS.user ||
        location.pathname === ROUTE_PATHS.setting ||
        location.pathname === '/setting/advanced/emission-sources'
      ) {
        setParentPath(location.pathname)
      } else {
        const pathArray = location.pathname.split('/')
        let activeRootPath = `/${pathArray[1]}`
        if (activeRootPath === '/scope3') {
          activeRootPath = `/${pathArray[1]}/${pathArray[2]}`
        } else if (`/${pathArray[1]}/${pathArray[2]}/${pathArray[3]}` === ROUTE_PATHS.settingRevenue) {
          activeRootPath = ROUTE_PATHS.settingRevenue
        }
        setParentPath(activeRootPath)
      }
    }
  }, [location.pathname])

  useEffect(() => {
    // Deactivate all sidebar elements
    for (const elements of Object.values(sidebarData)) {
      for (const [elementId, type] of elements) {
        const element = document.querySelector(`#${elementId}`)
        if (element) {
          if (type === types.activatable) {
            element.classList.remove('active')
          } else if (type === types.expandable) {
            element.setAttribute('aria-expanded', false)
          } else if (type === types.showable) {
            element.classList.remove('show')
          }
        }
      }
    }
    // Activate elements associated with the current path
    if (parentPath in sidebarData) {
      for (const [elementId, type] of sidebarData[parentPath]) {
        const element = document.querySelector(`#${elementId}`)
        if (element) {
          if (type === types.activatable) {
            element.classList.add('active')
          } else if (type === types.expandable) {
            element.setAttribute('aria-expanded', true)
          } else if (type === types.showable) {
            element.classList.add('show')
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentPath, account['client_scope'], account['client_scenario_analysis']])

  // Close sidemenu when overlay is clicked
  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay")
    if (verticalOverlay) {
      Array.from(verticalOverlay).forEach(function (element) {
        element.addEventListener("click", function () {
          document.body.classList.remove("vertical-sidebar-enable")
        })
      })
    }
  }, [])

  const categories = []
  for (let i = 1; i <= 15; i++) {
    if (account?.client_ghg_protocol_scope3_category_map?.[i]) {
      categories.push(i)
    }
  }

  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        <Link to={ROUTE_PATHS.dashboard} className="logo logo-dark">
          <span className="logo-sm">
            <img src={logoSmall} alt="" height="25" />
          </span>
          <span className="logo-lg">
            <img src={logo} alt="" height="65" />
          </span>
        </Link>
        <Link to={ROUTE_PATHS.dashboard} className="logo logo-light">
          <span className="logo-sm">
            <img src={logoSmall} alt="" height="25" />
          </span>
          <span className="logo-lg">
            <img src={logo} alt="" height="65" />
          </span>
        </Link>
        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line"></i>
        </button>
      </div>

      <SimpleBar
        id="scrollbar"
        className="h-100"
      >
        <div className="container-fluid">

          <div id="two-column-menu">
          </div>
          <ul className="navbar-nav" id="navbar-nav">
            <li className="menu-title">
              <span data-key="t-menu">
                {t('menu.title')}
              </span>
            </li>

            <ActivableWithIcon
              to={ROUTE_PATHS.dashboard}
              id="sidebarDashboard"
              icon="ri-dashboard-2-fill"
              label={t('menu.label1')}
            />

            <ExpandableWithIcon
              childToToggle="sidebarMeasureChildren"
              id="sidebarMeasure"
              icon="ri-calculator-fill"
              label={t('menu.label2')}
            >
              <Showable
                parentId="#navbar-nav"
                id="sidebarMeasureChildren"
              >
                <Expandable
                  childToToggle="sidebarMeasureEmissionChildren"
                  parentId="#sidebarMeasure"
                  id="sidebarMeasureEmission"
                  label={t('menu.label21')}
                >
                  <Showable
                    parentId="#sidebarMeasure"
                    id="sidebarMeasureEmissionChildren"
                  >
                    <Activable
                      to={ROUTE_PATHS.emission}
                      id="sidebarMeasureEmissionScopeOneTwo"
                      label={t('menu.label211')}
                    />
                    {/* eslint-disable-next-line */}
                    {account['client_scope'] > 1 ? (
                      <Expandable
                        childToToggle="sidebarMeasureEmissionScopeThreeChildren"
                        parentId="#sidebarMeasureEmission"
                        id="sidebarMeasureEmissionScopeThree"
                        label={t('menu.label212')}
                      >
                        <Showable
                          parentId="#sidebarMeasure"
                          id="sidebarMeasureEmissionScopeThreeChildren"
                        >
                          {categories.map((category) => (
                            category && (
                              <Activable
                                key={category}
                                to={ROUTE_PATHS[`scope3category${category}`]}
                                id={`sidebarMeasureEmissionScopeThreeCategory${category}`}
                                label={t(`menu.label212${category}`)}
                              // detail={t(`menu.labeldetail212${category}`)}
                              />
                            )
                          ))}
                        </Showable>
                      </Expandable>
                    ) : <></>}
                  </Showable>
                </Expandable>
                <Activable
                  to={ROUTE_PATHS.revenue}
                  id="sidebarMeasureRevenue"
                  label={t('menu.label22')}
                />
              </Showable>
            </ExpandableWithIcon>

            {account['client_subscription'] > 1 ?
              <ExpandableWithIcon
                childToToggle="sidebarActionChildren"
                id="sidebarAction"
                icon="ri-leaf-fill"
                label={t('menu.label3')}
              >
                <Showable
                  parentId="#navbar-nav"
                  id="sidebarActionChildren"
                >
                  {/* eslint-disable-next-line */}
                  {account['client_scenario_analysis'] == 1 && (
                    <Activable
                      to={ROUTE_PATHS.reduction}
                      id="sidebarActionReduction"
                      label={t('menu.label31')}
                    />
                  )}
                  <Activable
                    to={ROUTE_PATHS.removal}
                    id="sidebarActionRemoval"
                    label={t('menu.label32')}
                  />
                  <SoonMenu
                    href="#offset"
                    id="sidebarActionOffset"
                    label={t('menu.label34')}
                  />
                </Showable>
              </ExpandableWithIcon> : ""}

            {account['client_subscription'] > 1 ?
              <ExpandableWithIcon
                childToToggle="sidebarAnalyticChildren"
                id="sidebarAnalytic"
                icon="ri-pie-chart-fill"
                label={t('menu.label4')}
              >
                <Showable
                  parentId="#navbar-nav"
                  id="sidebarAnalyticChildren"
                >
                  {/* eslint-disable-next-line */}
                  {account['client_scenario_analysis'] == 1 && (
                    <>
                      <Activable
                        to={ROUTE_PATHS.scenario}
                        id="sidebarAnalyticScenario"
                        label={t('menu.label41')}
                      />
                      {
                        account['client_sbti'] === 1 && (
                          <Activable
                            to={ROUTE_PATHS.analyticsSBTi}
                            id="sidebarAnalyticScenarioSBTi"
                            label='SBTi'
                          />
                        )
                      }
                      <Activable
                        to={ROUTE_PATHS.analyticsCarbonIntensity}
                        id="sidebarAnalyticCarbonIntensity"
                        label={t('menu.label47')}
                      />
                    </>
                  )}
                  <Activable
                    to={ROUTE_PATHS.analyticsLocation}
                    id="sidebarAnalyticLocation"
                    label={t('menu.label623')}
                  />
                  <Activable
                    to={ROUTE_PATHS.analyticsLocationSummary}
                    id="sidebarAnalyticLocationSummary"
                    label={t('menu.label46')}
                  />
                </Showable>
              </ExpandableWithIcon> : ""}

            {account['client_subscription'] > 1 ?
              <ExpandableWithIcon
                childToToggle="sidebarReportChildren"
                id="sidebarReport"
                icon="ri-file-chart-fill"
                label={t('menu.label5')}
              >
                <Showable
                  parentId="#navbar-nav"
                  id="sidebarReportChildren"
                >
                  <Activable
                    to={ROUTE_PATHS.reportIso}
                    id="sidebarReportISO"
                    label={t('menu.label51')}
                  />
                  <Activable
                    to={ROUTE_PATHS.reportGhg}
                    id="sidebarReportGHG"
                    label={t('menu.label52')}
                  />
                  <Activable
                    to={ROUTE_PATHS.reportGri}
                    id="sidebarReportGRI"
                    label={t('menu.label53')}
                  />
                  {account['client_subscription'] === 3 && (
                    <Activable
                      to={ROUTE_PATHS.reportSummary}
                      id="sidebarReportSummary"
                      label={t('menu.label54')}
                    />
                  )}
                </Showable>
              </ExpandableWithIcon> : ""}

            <ExpandableWithIcon
              childToToggle="sidebarSettingChildren"
              id="sidebarSetting"
              icon="ri-settings-4-fill"
              label={t('menu.label6')}
            >
              <Showable
                parentId="#navbar-nav"
                id="sidebarSettingChildren"
              >
                {/* eslint-disable-next-line */}
                {account['client_subscription'] > 1 ?
                  account['client_scenario_analysis'] == 1 ?
                    <>
                      <Activable
                        to={ROUTE_PATHS.settingScenario}
                        id="sidebarSettingScenario"
                        label={t('menu.label61')}
                      />
                      <Activable
                        to={ROUTE_PATHS.settingCarbonIntensity}
                        id="sidebarSettingCarbonIntensity"
                        label={t('menu.label47')}
                      />
                    </>
                    : "" : ""}
                <Expandable
                  childToToggle="sidebarSettingAdvancedChildren"
                  parentId="#sidebarSettings"
                  id="sidebarSettingAdvanced"
                  label={t('menu.label62')}
                >
                  <Showable
                    parentId="#sidebarSettingAdvanced"
                    id="sidebarSettingAdvancedChildren"
                  >

                    {account['client_subscription'] > 1 ? (
                      <>
                        <Activable
                          to={ROUTE_PATHS.division}
                          id="sidebarSettingAdvancedDivision"
                          label={t('menu.label621')}
                        />
                        <Activable
                          to={ROUTE_PATHS.company}
                          id="sidebarSettingAdvancedCompany"
                          label={t('menu.label622')}
                        />
                        <Activable
                          to={ROUTE_PATHS.location}
                          id="sidebarSettingAdvancedLocation"
                          label={t('menu.label623')}
                        />
                        <Activable
                          to={ROUTE_PATHS.user}
                          id="sidebarSettingAdvancedUser"
                          label={t('menu.label624')}
                        />

                      </>
                    )
                      : ""}
                    <Activable
                      to={ROUTE_PATHS.settingRevenue}
                      id="sidebarSettingAdvancedRevenue"
                      label={t('menu.label22')}
                    />
                    {account['client_subscription'] > 1 ? (
                      <Activable
                        to={'/setting/advanced/emission-sources'}
                        id="sidebarSettingAdvancedEmissionSources"
                        label={t('menu.label9')}
                      />
                    ) : ""}

                  </Showable>
                </Expandable>

                <Activable
                  to={ROUTE_PATHS.setting}
                  id="sidebarSettingGeneral"
                  label={t('menu.label63')}
                />
              </Showable>
            </ExpandableWithIcon>
          </ul>
        </div>
      </SimpleBar>

      <div className="sidebar-background"></div>
    </div>
  )
}

export default MenuSection
