import {
    useEffect,
    useState,
} from 'react'
import { useSelector } from 'react-redux'

import axios from 'axios'
import { jsPDF } from 'jspdf'
import Swal from 'sweetalert2'
import html2canvas from 'html2canvas'

import { useTranslation } from 'react-i18next'

import ExcelJS from 'exceljs'

const titleStyles = {
    fontWeight: 'bold',
}

const thTitleStyles = {
    ...titleStyles,
    fontSize: '12px',
}

const subtitleStyle = {
    fontSize: '11px',
}

const thSubtitleTextStyles = {
    ...subtitleStyle,
    backgroundColor: '#438375',
}

const thSubtitleStyles = {
    ...subtitleStyle,
    ...thSubtitleTextStyles,
    ...titleStyles,
    backgroundColor: '#09564b',
}

const tdGasStyles = {
    width: '93px',
    minWidth: '93px',
}

const LABEL = {
    scopeOneRows: 'Scope 1',
    scopeTwoRows: 'Scope 2',
    scopeThreeRows: 'Scope 3',
    biogenicRows: 'Biogenic emission',
    scopeOneScopeTwoTotal: 'Total Emission Scope 1 + 2',
    scopeOneScopeTwoScopeThreeTotal: 'Total Emission Scope 1 + 2 + 3',
    intensityPerTotalRevenue: 'GHG Intensity Scope 1 + 2 / revenue',
    intensityThreeScopesPerTotalRevenue: 'GHG Intensity Scope 1 + 2 + 3 / revenue',
    intensityPerProductionUnit: 'GHG Intensity Scope 1 + 2 / unit of production',
    intensityPerTotalEnergy: 'GHG Intensity Scope 1 + 2 / total energy',
    scopeOneActivityRows: 'Total Emission from Energy Scope 1',
    scopeTwoActivityRows: 'Total Emission from Energy Scope 2',
    scopeThreeActivityRows: 'Total Emission from Energy Scope 3',
    additionalGasesCount: 'Other gasess',
}

const toLocaleString = (number, locales) => {
    const float = number || 0
    return float.toLocaleString(locales, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

const parseUnit = (string) => {
    if (string === 'tCO2e') {
        return <span>tCO<sub>2</sub>e</span>
    } else if (string === 'm3') {
        return <span>m<sup>3</sup></span>
    } else if (string === 'tonCO2e') {
        return <span>tonCO<sub>2</sub>e</span>
    } else {
        return string
    }
}

const parseUnitForExcel = (string) => {
    if (string === 'tCO2e') {
        return 'tCO₂e'
    } else if (string === 'm3') {
        return 'm³'
    } else if (string === 'tonCO2e') {
        return 'tonCO₂e'
    } else {
        return string
    }
}

const DownloadDropdown = ({
    onGenerateReport,
    onDownloadExcel,
}) => {
    const { t } = useTranslation()

    return (
        <div className="flex-shrink-0" data-html2canvas-ignore="true">
            <ul className="list-inline text-end mb-0">
                <li className="list-inline-item m-0">
                    <div className="dropdown">
                        <button
                            className="btn btn-ghost-secondary btn-icon btn-sm"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i className="ri-more-2-fill "></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                            <button
                                type="button"
                                className="dropdown-item"
                                onClick={onGenerateReport}
                            >
                                <i className="ri-file-line align-bottom text-muted me-2"></i>
                                {t('report.saveAsPdf')}
                            </button>
                            <button
                                type="button"
                                className="dropdown-item"
                                onClick={onDownloadExcel}
                            >
                                <i className="ri-file-excel-line align-bottom text-muted me-2"></i>
                                {t('report.saveAsExcel')}
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

const TRScope = ({
    scope = 'Scope 1',
    hasTotal = true,
    data,
    additionalColumn,
}) => {
    const { i18n } = useTranslation()
    const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'

    if (Array.isArray(data) && data.length > 0) {
        const items = data.map(item => {
            const newItem = [
                { column: 1, value: item.category_name },
                { column: 2, value: toLocaleString(item.total_CO2, locales) },
                { column: 3, value: toLocaleString(item.total_CH4, locales) },
                { column: 4, value: toLocaleString(item.total_N2O, locales) },
                { column: 5, value: toLocaleString(item.total_HFCs, locales) },
                { column: 6, value: toLocaleString(item.total_PFCs, locales) },
                { column: 7, value: toLocaleString(item.total_SF6, locales) },
            ]
            if (additionalColumn === 1) {
                newItem.push({ column: 8, value: toLocaleString(item.total_NF3, locales) })
            } else if (additionalColumn === 2) {
                newItem.push({ column: 8, value: toLocaleString(item.total_NF3, locales) })
                newItem.push({ column: 9, value: toLocaleString(item.total_unspecified, locales) })
            }
            newItem.push({ column: newItem.length + 1, value: toLocaleString(item.record_total, locales) })
            return newItem
        })
        return (
            <>
                <tr>
                    <th
                        colSpan={9 + additionalColumn}
                    >
                        {scope}
                    </th>
                </tr>
                {items.map((row, i, arr) => {
                    return (
                        <tr key={i}>
                            {row.map(({ column, value }) => {
                                return (
                                    <td
                                        key={column}
                                        colSpan={column === 1 ? 2 : 1}
                                        style={{
                                            ...tdGasStyles,
                                            textAlign: column === 1 ? 'left' : 'right',
                                            fontWeight: i === arr.length - 1 & hasTotal ? 'bold' : 'inherit',
                                        }}
                                    >
                                        {value}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </>
        )
    } else {
        return null
    }
}

const EmptyTableBody = () => {
    return (
        <tbody>
            <tr>
                <td
                    colSpan={9}
                >
                    &nbsp;
                </td>
            </tr>
        </tbody>
    )
}

const TRActivity = ({
    scope,
    rows,
    additionalColumn,
}) => {
    const { t, i18n } = useTranslation()
    const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'
    if (Array.isArray(rows) && rows.length > 0) {
        const count = {}
        const categoryNames = [...new Set(rows.map(item => item.category_name))]
        categoryNames.forEach((item) => {
            const len = rows.filter(row => row.category_name === item).length
            count[item] = {
                rowCount: len,
                startIndex: rows.findIndex(row => row.category_name === item),
            }
        })
        const items = rows.map((item, index) => {
            return [
                {
                    column: 1,
                    value: item.category_name,
                    rowSpan: count[item.category_name].rowCount,
                    colSpan: scope === LABEL.scopeThreeRows ? 1 : 1, // 4 : 1
                    hide: index !== count[item.category_name].startIndex,
                    textAlign: 'left',
                },
                {
                    column: 2,
                    value: item.emission_name,
                    rowSpan: 1,
                    colSpan: 3 + additionalColumn,
                    // hide: scope === LABEL.scopeThreeRows,
                    textAlign: 'left',
                },
                {
                    column: 3,
                    value: toLocaleString(item.record_amount, locales),
                    rowSpan: 1,
                    colSpan: 2,
                    textAlign: 'right',
                },
                {
                    column: 4,
                    value: parseUnit(item.emission_unit), // TODO: Double check on emission_unit. It'd be deprecated
                    rowSpan: 1,
                    textAlign: 'left',
                    colSpan: 1,
                },
                {
                    column: 5,
                    value: toLocaleString(scope === LABEL.scopeThreeRows ? item.record_amount : item.record_total, locales),
                    rowSpan: 1,
                    colSpan: 1,
                    textAlign: 'right',
                },
                {
                    column: 6,
                    value: toLocaleString(item.total_energy_in_gj, locales),
                    rowSpan: 1,
                    colSpan: 1,
                    textAlign: 'right',
                },
            ]
        })

        let defaultHeaders = [
            { column: 1, label: `${scope}`, colSpan: (scope === LABEL.scopeThreeRows) ? 1 : 1 }, // 4 : 1
            { column: 2, label: t('report.source'), colSpan: 3 + additionalColumn },
            { column: 3, label: t('report.activityData'), colSpan: 2 },
            { column: 4, label: t('report.unit'), colSpan: 1 },
            { column: 5, label: <span>{t('report.total')} (tCO<sub>2</sub>e)</span>, colSpan: 1 },
            { column: 6, label: `${t('report.totalEnergy')} (GJ)`, colSpan: 1 },
        ]
        // const headers = (scope === LABEL.scopeThreeRows) ? defaultHeaders.toSpliced(1, 1) : defaultHeaders
        const headers =  defaultHeaders

        return (
            <>
                <tr>
                    {headers.map(th => {
                        return (
                            <th
                                key={th.column}
                                colSpan={th.colSpan}
                            >
                                {th.label}
                            </th>

                        )
                    })}
                </tr>
                {items.map((row, i) => {
                    return (
                        <tr key={i}>
                            {row.map(({
                                column,
                                value,
                                rowSpan,
                                colSpan,
                                textAlign,
                                hide = false,
                            }) => {
                                if (hide) {
                                    return null
                                } else {
                                    return (
                                        <td
                                            key={column}
                                            rowSpan={rowSpan}
                                            colSpan={colSpan}
                                            style={{
                                                textAlign: textAlign,
                                            }}
                                        >
                                            {value}
                                        </td>
                                    )
                                }
                            })}
                        </tr>
                    )
                })}
            </>
        )
    } else {
        return null
    }
}

function ReportGHGInventory() {
    const { t, i18n } = useTranslation()
    const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'
    const account = useSelector((state) => state.account.value)


    const [optionYear, setOptionYear] = useState([])
    const [selectedYear, setSelectedYear] = useState('')
    const [ghg, setGHG] = useState({})
    const [additionalGassesCount, setAdditionalGassesCount] = useState(0)
    const tableHeaderDefault = [
        {
            key: 1,
            label: t('report.scopeOrEmissionSource'),
            labelPlainText: t('report.scopeOrEmissionSource'),
            colSpan: 3,
            objecKey: 'category_name',
        },
        {
            key: 2,
            label: <span>CO<sub>2</sub></span>,
            labelPlainText: 'CO₂',
            colSpan: 1,
            objecKey: 'total_CO2',
        },
        {
            key: 3,
            label: <span>CH<sub>4</sub></span>,
            labelPlainText: 'CH₄',
            colSpan: 1,
            objecKey: 'total_CH4',
        },
        {
            key: 4,
            label: <span>N<sub>2</sub>O</span>,
            labelPlainText: 'N₂O',
            colSpan: 1,
            objecKey: 'total_N2O',
        },
        {
            key: 5,
            label: 'HFCs',
            labelPlainText: 'HFCs',
            colSpan: 1,
            objecKey: 'total_HFCs',
        },
        {
            key: 6,
            label: 'PFCs',
            labelPlainText: 'PFCs',
            colSpan: 1,
            objecKey: 'total_PFCs',
        },
        {
            key: 7,
            label: <span>SF<sub>6</sub></span>,
            labelPlainText: 'SF₆',
            colSpan: 1,
            objecKey: 'total_SF6',
        },
        {
            key: 8,
            label: <span>{t('report.total')}<br />(tCO<sub>2</sub>e)</span>,
            labelPlainText: `${t('report.total')} (tCO₂e)`,
            colSpan: 2,
            objecKey: 'record_total',
        }
    ]
    const [tableHeader, setTableHader] = useState(tableHeaderDefault)


    useEffect(() => {
        const controller = new AbortController()

        const getYears = async () => {
            try {
                const response = await axios({
                    withCredentials: true,
                    method: 'GET',
                    headers: { 'content-type': 'application/json' },
                    url: `${process.env.REACT_APP_BASE_SERVER}/record-year-and-company-summaries-lookup`,
                    signal: controller.signal,
                })
                if (response.data.ok) {
                    if (Array.isArray(response.data.data.years)) {
                        const lastYear = new Date().getFullYear() - 1
                        setOptionYear(response.data.data.years)
                        if (response.data.data.years.length > 0) {
                            const findLastYear = response.data.data.years.find(d => d.year === lastYear)
                            if (findLastYear) {
                                setSelectedYear(findLastYear.year)
                            } else {
                                setSelectedYear(response.data.data.years[0].year)
                            }
                        }
                    }
                    if (Array.isArray(response.data.data.companies)) {
                        //setCompanyOptions(response.data.data.companies)
                    }
                }
            } catch (e) {
                console.error(e)
                let message = ''
                if (typeof e.response?.data?.message === 'string') {
                    message = e.response.data.message
                } else if (e.message) {
                    message = e.message
                }
                // Prevent abort controller error from being shown
                if (e.code && e.code !== 'ERR_CANCELED') {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        html: `<p>${message}</p>`,
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    })
                }
            }
        }
        getYears()

        return () => controller.abort()
    }, [])

    useEffect(() => {
        const controller = new AbortController()

        const getGHGReport = async () => {
            try {
                const config = {
                    withCredentials: true,
                    method: 'GET',
                    headers: { 'content-type': 'application/json' },
                    url: `${process.env.REACT_APP_BASE_SERVER}/ghg-report-lookup`,
                    signal: controller.signal,
                    params: {
                        gwp: new URLSearchParams(window.location.search).get('gwp'),
                        year: selectedYear,
                    }
                }
                const responseGetReportGHG = await axios(config)
                if (responseGetReportGHG.data.success) {
                    setGHG(responseGetReportGHG.data.data)
                    const otherGassesCount = responseGetReportGHG.data.data[LABEL.additionalGasesCount]
                    setAdditionalGassesCount(otherGassesCount)
                    if (otherGassesCount === 0) {
                        setTableHader(tableHeaderDefault)
                    } else if (otherGassesCount === 1) {
                        const nF3 = {
                            key: 8,
                            label: <span>NF<sub>3</sub></span>,
                            labelPlainText: 'NF₃',
                            colSpan: 1,
                            objecKey: 'total_NF3',
                        }
                        setTableHader(tableHeaderDefault.toSpliced(7, 1, nF3, {
                            key: 9,
                            label: <span>{t('report.total')}<br />(tCO<sub>2</sub>e)</span>,
                            labelPlainText: `${t('report.total')} (tCO₂e)`,
                            colSpan: 2,
                            objecKey: 'record_total',
                        }))
                    }
                }
            } catch (e) {
                let message = ''
                if (typeof e.response?.data?.message === 'string') {
                    message = e.response.data.message
                } else if (e.message) {
                    message = e.message
                }
                // Prevent abort controller error from being shown
                if (e.code && e.code !== 'ERR_CANCELED') {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        html: `<p>${message}</p>`,
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    })
                }
            }
        }
        if (selectedYear) getGHGReport()

        return () => controller.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear])

    const onSelectYear = (e) => {
        setSelectedYear(e.target.value)
    }

    const onGenerateReport = async () => {
        const canvas = await html2canvas(document.getElementById('reportBody'))
        const image = canvas.toDataURL("image/png", 1.0)

        var imgWidth = 210
        var pageHeight = 300
        var imgHeight = (canvas.height - 30) * imgWidth / canvas.width
        var heightLeft = imgHeight
        var doc = new jsPDF('p', 'mm')
        var position = 8

        doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight

        while (heightLeft >= 0) {
            position += heightLeft - imgHeight + 5 // top padding for other pages
            doc.addPage()
            doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight)
            heightLeft -= pageHeight
        }
        doc.save(`${selectedYear} ${account.client_name} Consolidated Statement of Greenhouse Gas Emissions.pdf`)
    }

    const onGenerateReportInExcel = () => {
        try {
            if (selectedYear) {
                // Create WorkBook
                const wb = new ExcelJS.Workbook()

                // Excel Properties
                wb.creator = 'TruCount'
                wb.lastModifiedBy = 'Trucount System'
                wb.created = new Date()
                wb.modified = new Date()

                // Create Sheet
                const ws = wb.addWorksheet('GHG Inventory Report', {
                    pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 },
                })
                ws.headerFooter.oddFooter = "&LGRI&C&F&RPage &P of &N";

                // Set the column width using letters or numbers
                const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                ws.columns = numbers.map(no => ({ key: no, width: 15 }))
                ws.views = [{
                    showGridLines: false,
                }]

                // Style attributes
                const font = { size: 12 }
                const centerAlignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                }
                const leftAlignment = {
                    vertical: 'middle',
                    horizontal: 'left',
                    wrapText: true,
                }
                const rightAlignment = {
                    vertical: 'middle', horizontal: 'right'
                }
                const style = { style: 'thin', color: { argb: 'e9ebec' } } // #e9ebec
                const otherStyle = { style: 'thin', color: { argb: 'ffffff' } } // #ffffff
                const border = {
                    top: style,
                    left: otherStyle,
                    bottom: style,
                    right: otherStyle
                }
                const fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '09564b' }, // #09564b
                }
                const fontColorWhite = { color: { argb: 'ffffff' } } // #ffffff

                const STYLE_TYPE = {
                    noBorder: 'no_border',
                    default: 'default',

                    fillDarkerBlue: 'fill_darker_blue',
                    fillBlue: 'fill_blue',
                    fillGray: 'fill_gray',

                    rightAlignment: 'right_alignment',
                    leftAlignment: 'left_alignment',
                    centerAlignment: 'center_alignment',

                    boldFont: 'bold_font',

                    bigTitleText: 'big_title_text',
                    titleText: 'title_text',
                }

                const applyCellStyles = (selectedCell, customAlignment) => {
                    selectedCell.font = font
                    selectedCell.alignment = customAlignment || leftAlignment
                    selectedCell.border = border
                }

                const renderCell = ({
                    startRow,
                    startColumn,
                    endRow,
                    endColumn,
                    value,
                    styleType,
                }) => {
                    const cell = ws.getRow(startRow).getCell(startColumn)
                    cell.value = value
                    if (
                        Array.isArray(styleType) &&
                        styleType.some(type => type === STYLE_TYPE.bigTitleText)
                    ) {
                        cell.font = { size: 24, bold: true }
                        cell.alignment = leftAlignment
                    } else if (
                        Array.isArray(styleType) &&
                        styleType.some(type => type === STYLE_TYPE.titleText)
                    ) {
                        cell.font = { size: 12, bold: true }
                        cell.alignment = leftAlignment
                    } else if (
                        Array.isArray(styleType) &&
                        styleType.some(type => type === STYLE_TYPE.noBorder)
                    ) {
                        cell.font = font
                        cell.alignment = leftAlignment
                    } else {
                        applyCellStyles(cell)
                        // Overwrite the above styles
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.fillDarkerBlue)
                        ) {
                            cell.fill = fill
                            cell.font = { ...font, ...fontColorWhite }
                            cell.border = null
                        }
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.fillBlue)
                        ) {
                            const fgColorBlue = { fgColor: { argb: '438375' } } // #438375
                            cell.fill = { ...fill, ...fgColorBlue }
                            cell.font = { ...font, ...fontColorWhite }
                            cell.border = null
                        }
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.fillGray)
                        ) {
                            const fgColorBlue = { fgColor: { argb: 'F5F5F5' } } // #F5F5F5
                            cell.fill = { ...fill, ...fgColorBlue }
                            cell.font = { ...font, 'bold': true }
                            cell.border = {
                                top: style,
                                left: null,
                                bottom: style,
                                right: null,
                            }
                        }
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.rightAlignment)
                        ) {
                            cell.alignment = rightAlignment
                        }
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.leftAlignment)
                        ) {
                            cell.alignment = leftAlignment
                        }
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.centerAlignment)
                        ) {
                            cell.alignment = centerAlignment
                        }
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.boldFont)
                        ) {
                            cell.font = { ...font, 'bold': true }
                        }
                    }
                    ws.mergeCells(startRow, startColumn, endRow, endColumn)
                }

                // Part 1
                const partOne = [
                    {
                        value: account.client_name,
                        startRow: 1,
                        fontSize: 24,
                        styleType: [STYLE_TYPE.bigTitleText],
                    },
                    {
                        value: selectedYear,
                        startRow: 2,
                        fontSize: 12,
                        styleType: [STYLE_TYPE.titleText],
                    },
                ]
                partOne.forEach(item => {
                    renderCell({
                        startRow: item.startRow,
                        startColumn: 1,
                        endRow: item.startRow,
                        endColumn: 3,
                        value: item.value,
                        styleType: item.styleType,
                    })
                })

                const endColumnWholeRows = 11 // A to K

                // Part 2
                const startRowOfPartTwo = partOne[partOne.length - 1].startRow + 2

                renderCell({
                    startRow: startRowOfPartTwo,
                    startColumn: 1,
                    endRow: startRowOfPartTwo,
                    endColumn: endColumnWholeRows,
                    value: `${t('report.GHGInventorySummary')} ${t('report.GHGBasedOn')} ${t('report.GHGProtocol')}`,
                    styleType: [STYLE_TYPE.fillDarkerBlue, STYLE_TYPE.centerAlignment],
                })

                const renderSummaryByScope = ({
                    data,
                    currentHeaderColumn,
                    firstItemStartRow,
                    colSpan,
                    objecKey,
                    bold = true,
                }) => {
                    data.forEach((
                        scopeItem,
                        scopeItemIndex,
                        scopeItems,
                    ) => {
                        const alignLeft = currentHeaderColumn === 1
                        const alignRight = currentHeaderColumn !== 1
                        const boldText = (
                            currentHeaderColumn === 1 &&
                            scopeItems.length - 1 === scopeItemIndex
                        ) ||
                            scopeItems.length - 1 === scopeItemIndex;

                        const styleType = []
                        if (alignLeft) styleType.push(STYLE_TYPE.leftAlignment)
                        if (alignRight) styleType.push(STYLE_TYPE.rightAlignment)
                        if (bold && boldText) styleType.push(STYLE_TYPE.boldFont)

                        renderCell({
                            startRow: firstItemStartRow + scopeItemIndex,
                            startColumn: currentHeaderColumn,
                            endRow: firstItemStartRow + scopeItemIndex,
                            endColumn: currentHeaderColumn + colSpan - 1,
                            value: typeof scopeItem[objecKey] === 'string'
                                ? scopeItem[objecKey]
                                : toLocaleString(scopeItem[objecKey], locales),
                            styleType,
                        })
                    })
                }

                let currentColumn = 1
                const showScope1 = Array.isArray(ghg[LABEL.scopeOneRows]) && ghg[LABEL.scopeOneRows].length > 0
                const showScope2 = Array.isArray(ghg[LABEL.scopeTwoRows]) && ghg[LABEL.scopeTwoRows].length > 0
                const showScope3 = Array.isArray(ghg[LABEL.scopeThreeRows]) && ghg[LABEL.scopeThreeRows].length > 0
                const showBiogenic = Array.isArray(ghg[LABEL.biogenicRows]) && ghg[LABEL.biogenicRows].length > 0

                let startRowOfScope1 = 0
                if (showScope1) {
                    startRowOfScope1 = startRowOfPartTwo + 2
                }

                let startRowOfScope2 = 0
                if (showScope2) {
                    if (!showScope1) {
                        startRowOfScope2 = startRowOfPartTwo + 2
                    } else {
                        startRowOfScope2 = startRowOfScope1 +
                            ghg[LABEL.scopeOneRows].length +
                            1
                    }
                }

                let startRowOfScope3 = 0
                if (showScope3) {
                    if (!showScope1 && !showScope2) {
                        startRowOfScope3 = startRowOfPartTwo + 2
                    } else {
                        if (showScope1) {
                            startRowOfScope3 = startRowOfScope1 +
                                ghg[LABEL.scopeOneRows].length + 1
                        }
                        if (showScope2) {
                            startRowOfScope3 = startRowOfScope2 +
                                ghg[LABEL.scopeTwoRows].length + 1
                        }
                    }
                }

                let startRowOfBiogenic = 0
                if (showBiogenic) {
                    if (!showScope1 && !showScope2 && !showScope3) {
                        startRowOfBiogenic = startRowOfPartTwo + 2
                    } else {
                        if (showScope1) {
                            startRowOfBiogenic = startRowOfScope1 +
                                ghg[LABEL.scopeOneRows].length + 1
                        }
                        if (showScope2) {
                            startRowOfBiogenic = startRowOfScope2 +
                                ghg[LABEL.scopeTwoRows].length + 1
                        }
                        if (showScope3) {
                            startRowOfBiogenic = startRowOfScope3 +
                                ghg[LABEL.scopeThreeRows].length + 1
                        }
                    }
                }

                tableHeader.forEach((
                    {
                        labelPlainText,
                        colSpan,
                        objecKey,
                    },
                    tableHeaderIndex,
                ) => {
                    renderCell({
                        startRow: startRowOfPartTwo + 1,
                        startColumn: currentColumn,
                        endRow: startRowOfPartTwo + 1,
                        endColumn: currentColumn + colSpan - 1,
                        value: labelPlainText,
                        styleType: [STYLE_TYPE.fillBlue, STYLE_TYPE.centerAlignment],
                    })

                    // Scope 1
                    if (showScope1) {
                        if (tableHeaderIndex === 0) {
                            renderCell({
                                startRow: startRowOfScope1,
                                startColumn: 1,
                                endRow: startRowOfScope1,
                                endColumn: endColumnWholeRows,
                                value: 'Scope 1',
                                styleType: [STYLE_TYPE.fillGray, STYLE_TYPE.centerAlignment],
                            })
                        }
                        renderSummaryByScope({
                            data: ghg[LABEL.scopeOneRows],
                            currentHeaderColumn: currentColumn,
                            firstItemStartRow: startRowOfScope1 + 1,
                            colSpan,
                            objecKey,
                            tableHeaderIndex,
                        })
                    }

                    // Scope 2
                    if (showScope2) {
                        if (tableHeaderIndex === 0) {
                            renderCell({
                                startRow: startRowOfScope2,
                                startColumn: 1,
                                endRow: startRowOfScope2,
                                endColumn: endColumnWholeRows,
                                value: 'Scope 2',
                                styleType: [STYLE_TYPE.fillGray, STYLE_TYPE.centerAlignment],
                            })
                        }
                        renderSummaryByScope({
                            data: ghg[LABEL.scopeTwoRows],
                            currentHeaderColumn: currentColumn,
                            firstItemStartRow: startRowOfScope2 + 1,
                            colSpan,
                            objecKey,
                            tableHeaderIndex,
                        })
                    }

                    // Scope 3
                    if (showScope3) {
                        if (tableHeaderIndex === 0) {
                            renderCell({
                                startRow: startRowOfScope3,
                                startColumn: 1,
                                endRow: startRowOfScope3,
                                endColumn: endColumnWholeRows,
                                value: 'Scope 3',
                                styleType: [STYLE_TYPE.fillGray, STYLE_TYPE.centerAlignment],
                            })
                        }
                        renderSummaryByScope({
                            data: ghg[LABEL.scopeThreeRows],
                            currentHeaderColumn: currentColumn,
                            firstItemStartRow: startRowOfScope3 + 1,
                            colSpan,
                            objecKey,
                            tableHeaderIndex,
                        })
                    }

                    // Biogenic
                    if (showBiogenic) {
                        if (tableHeaderIndex === 0) {
                            renderCell({
                                startRow: startRowOfBiogenic,
                                startColumn: 1,
                                endRow: startRowOfBiogenic,
                                endColumn: endColumnWholeRows,
                                value: 'Biogenic Emission',
                                styleType: [STYLE_TYPE.fillGray, STYLE_TYPE.centerAlignment],
                            })
                        }
                        renderSummaryByScope({
                            data: ghg[LABEL.biogenicRows],
                            currentHeaderColumn: currentColumn,
                            firstItemStartRow: startRowOfBiogenic + 1,
                            colSpan,
                            objecKey,
                            tableHeaderIndex,
                            bold: false,
                        })
                    }

                    currentColumn += colSpan
                })

                // Part 3
                let startRowOfPartThree = startRowOfPartTwo + 3
                if (showScope1) {
                    startRowOfPartThree += 1 + ghg[LABEL.scopeOneRows].length
                }
                if (showScope2) {
                    startRowOfPartThree += 1 + ghg[LABEL.scopeTwoRows].length
                }
                if (showScope3) {
                    startRowOfPartThree += 1 + ghg[LABEL.scopeThreeRows].length
                }
                if (showBiogenic) {
                    startRowOfPartThree += 1 + ghg[LABEL.biogenicRows].length
                }

                renderCell({
                    startRow: startRowOfPartThree - 1,
                    startColumn: 1,
                    endRow: startRowOfPartThree - 1,
                    endColumn: endColumnWholeRows,
                    value: '',
                    styleType: [STYLE_TYPE.centerAlignment],
                })

                const partThree = [
                    {
                        header: `${t('report.totalEmissionScopeOneAndTwo')} (tCO₂e)`,
                        value: toLocaleString(ghg[LABEL.scopeOneScopeTwoTotal], locales),
                        startRow: startRowOfPartThree,
                    },
                ]
                if (showScope3) {
                    partThree.push({
                        header: `${t('report.totalEmissionScopeOneTwoAndThree')} (tCO₂e)`,
                        value: toLocaleString(ghg[LABEL.scopeOneScopeTwoScopeThreeTotal], locales),
                        startRow: startRowOfPartThree + 1,
                    })
                }
                partThree.push(...[
                    {
                        header: `${t('report.totalRevenue')} (${ghg.revenue.unit})`,
                        value: toLocaleString(ghg[LABEL.scopeOneScopeTwoScopeThreeTotal], locales),
                        startRow: showScope3 ? startRowOfPartThree + 2 : startRowOfPartThree + 1,
                    },
                    {
                        header: `${t('report.GHGIntensityScopeOneAndTwo')} (tCO₂e) / ${t('report.revenue')} (${ghg.revenue.unit})`,
                        value: `${toLocaleString(ghg[LABEL.intensityPerTotalRevenue], locales)} (tCO₂e) ${t('report.per')} ${ghg.revenue.unit}`,
                        startRow: showScope3 ? startRowOfPartThree + 3 : startRowOfPartThree + 2,
                    }
                ])
                if (showScope3) {
                    partThree.push({
                        header: `${t('report.GHGIntensityScopeOneTwoAndThree')} (tCO₂e) / ${t('report.revenue')} (${ghg.revenue.unit})`,
                        value: `${toLocaleString(ghg[LABEL.intensityThreeScopesPerTotalRevenue], locales)} (tCO₂e) ${t('report.per')} ${ghg.revenue.unit}`,
                        startRow: startRowOfPartThree + 4,
                    })
                }
                partThree.push({
                    header: `${t('report.GHGIntensityScopeOneAndTwo')} (tCO₂e) / ${t('report.totalEnergy')} (GJ)`,
                    value: `${toLocaleString(ghg[LABEL.intensityPerTotalEnergy], locales)} (tCO₂e) ${t('report.per')} GJ`,
                    startRow: showScope3 ? startRowOfPartThree + 5 : startRowOfPartThree + 3,
                })
                partThree.forEach(item => {
                    renderCell({
                        startRow: item.startRow,
                        startColumn: 1,
                        endRow: item.startRow,
                        endColumn: 8,
                        value: item.header,
                        styleType: [STYLE_TYPE.fillGray, STYLE_TYPE.centerAlignment],
                    })
                    renderCell({
                        startRow: item.startRow,
                        startColumn: 9,
                        endRow: item.startRow,
                        endColumn: endColumnWholeRows,
                        value: item.value,
                        styleType: [STYLE_TYPE.rightAlignment],
                    })
                })

                // Part 4
                const startRowOfPartFour = startRowOfPartThree + partThree.length + 1

                renderCell({
                    startRow: startRowOfPartFour - 1,
                    startColumn: 1,
                    endRow: startRowOfPartFour - 1,
                    endColumn: endColumnWholeRows,
                    value: '',
                    styleType: [STYLE_TYPE.centerAlignment],
                })

                renderCell({
                    startRow: startRowOfPartFour,
                    startColumn: 1,
                    endRow: startRowOfPartFour,
                    endColumn: endColumnWholeRows,
                    value: t('report.totalEmissionFromEnergy'),
                    styleType: [STYLE_TYPE.fillDarkerBlue, STYLE_TYPE.centerAlignment],
                })

                const renderPartFourByScope = ({
                    scope,
                    scopeActivityRows,
                    firstHeaderStartRow,
                }) => {
                    const partFourHeaders = [
                        {
                            labelPlainText: `Scope ${scope}`,
                            startRow: startRowOfPartThree,
                            colSpan: scope === 3 ? 6 : 3,
                            objectKey: 'category_name',
                            dataTextAlignKey: 'leftAlignment',
                        },
                        {
                            labelPlainText: `${t('report.source')}`,
                            startRow: startRowOfPartThree,
                            colSpan: 3,
                            objectKey: 'emission_name',
                            dataTextAlignKey: 'leftAlignment',
                            hideLabelPlainText: scope === 3,
                        },
                        {
                            labelPlainText: `${t('report.activityData')}`,
                            startRow: startRowOfPartThree,
                            colSpan: 1,
                            objectKey: 'record_amount',
                            dataTextAlignKey: 'rightAlignment',
                        },
                        {
                            labelPlainText: `${t('report.unit')}`,
                            startRow: startRowOfPartThree,
                            colSpan: 1,
                            objectKey: 'emission_unit', // TODO: Double check on emission_unit. It'd be deprecated
                            dataTextAlignKey: 'leftAlignment',
                        },
                        {
                            labelPlainText: `${t('report.total')} (tCO₂e)`,
                            startRow: startRowOfPartThree,
                            colSpan: 1,
                            objectKey: scope === 3 ? 'record_amount' : 'record_total',
                            dataTextAlignKey: 'rightAlignment',
                        },
                        {
                            labelPlainText: `${t('report.totalEnergy')} (GJ)`,
                            startRow: startRowOfPartThree,
                            colSpan: 2,
                            objectKey: 'total_energy_in_gj',
                            dataTextAlignKey: 'rightAlignment',
                        },
                    ]

                    let partFourCurrentColumn = 1
                    partFourHeaders.forEach(({
                        labelPlainText,
                        colSpan,
                        hideLabelPlainText = false,
                    }) => {
                        if (!hideLabelPlainText) {
                            renderCell({
                                startRow: firstHeaderStartRow,
                                startColumn: partFourCurrentColumn,
                                endRow: firstHeaderStartRow + 1,
                                endColumn: partFourCurrentColumn + colSpan - 1,
                                value: labelPlainText,
                                styleType: [STYLE_TYPE.fillGray, STYLE_TYPE.centerAlignment],
                            })
                            partFourCurrentColumn += colSpan
                        }

                    })

                    if (scopeActivityRows.length > 0) {
                        const count = {}
                        const categoryNames = [...new Set(scopeActivityRows.map(item => item.category_name))]
                        categoryNames.forEach((item) => {
                            const len = scopeActivityRows.filter(row => row.category_name === item).length
                            count[item] = {
                                rowCount: len,
                                startIndex: scopeActivityRows.findIndex(row => row.category_name === item),
                            }
                        })

                        scopeActivityRows.forEach((scopeActivityRowItem, scopeItemIndex) => {
                            let startColumnHeaderActivityRow = 1
                            partFourHeaders.forEach(({
                                objectKey,
                                colSpan,
                                dataTextAlignKey,
                                hideLabelPlainText,
                            }) => {
                                const columnData = scopeActivityRowItem[objectKey]
                                let value = ''
                                if (objectKey === 'emission_unit') { // TODO: Double check on emission_unit. It'd be deprecated
                                    value = parseUnitForExcel(columnData)
                                } else if (objectKey === 'total_energy_in_gj' && scope === 3) {
                                    value = toLocaleString(0, locales)
                                } else if (typeof columnData === 'string') {
                                    value = columnData
                                } else if (typeof columnData === 'number') {
                                    value = toLocaleString(columnData, locales)
                                }

                                const styleType = []
                                if (dataTextAlignKey === 'leftAlignment') styleType.push(STYLE_TYPE.leftAlignment)
                                if (dataTextAlignKey === 'rightAlignment') styleType.push(STYLE_TYPE.rightAlignment)

                                if (
                                    objectKey === 'category_name' &&
                                    count[columnData] !== undefined
                                ) {
                                    const rowSpan = count[columnData].rowCount
                                    const hide = scopeItemIndex !== count[columnData].startIndex
                                    if (!hide) {
                                        renderCell({
                                            startRow: firstHeaderStartRow + 2 + scopeItemIndex,
                                            startColumn: startColumnHeaderActivityRow,
                                            endRow: firstHeaderStartRow + 2 + scopeItemIndex + (rowSpan - 1),
                                            endColumn: startColumnHeaderActivityRow + colSpan - 1,
                                            value,
                                            styleType,
                                        })
                                    }
                                } else {
                                    if (!hideLabelPlainText) {
                                        renderCell({
                                            startRow: firstHeaderStartRow + 2 + scopeItemIndex,
                                            startColumn: startColumnHeaderActivityRow,
                                            endRow: firstHeaderStartRow + 2 + scopeItemIndex,
                                            endColumn: startColumnHeaderActivityRow + colSpan - 1,
                                            value,
                                            styleType,
                                        })
                                    }
                                }
                                if (!hideLabelPlainText) {
                                    startColumnHeaderActivityRow += colSpan
                                }
                            })

                        })
                    }
                }

                const showScopeOneActivityRows = Array.isArray(ghg[LABEL.scopeOneActivityRows]) && ghg[LABEL.scopeOneActivityRows].length > 0
                const showScopeTwoActivityRows = Array.isArray(ghg[LABEL.scopeTwoActivityRows]) && ghg[LABEL.scopeTwoActivityRows].length > 0
                const showScopeThreeActivityRows = Array.isArray(ghg[LABEL.scopeThreeActivityRows]) && ghg[LABEL.scopeThreeActivityRows].length > 0

                // Scope 1
                if (showScopeOneActivityRows) {
                    renderPartFourByScope({
                        scope: 1,
                        scopeActivityRows: ghg[LABEL.scopeOneActivityRows],
                        firstHeaderStartRow: startRowOfPartFour + 1,
                    })
                }

                // Scope 2
                if (showScopeTwoActivityRows) {
                    let scope2ActivityStartRow = 0
                    if (showScopeOneActivityRows) {
                        scope2ActivityStartRow = startRowOfPartFour + 1 +
                            ghg[LABEL.scopeOneActivityRows].length + 2
                    } else {
                        scope2ActivityStartRow = startRowOfPartFour + 1
                    }
                    renderPartFourByScope({
                        scope: 2,
                        scopeActivityRows: ghg[LABEL.scopeTwoActivityRows],
                        firstHeaderStartRow: scope2ActivityStartRow,
                    })
                }

                // Scope 3
                if (showScopeThreeActivityRows) {
                    let scope3ActivityStartRow = 0
                    if (!showScopeOneActivityRows && !showScopeTwoActivityRows) {
                        scope3ActivityStartRow = startRowOfPartFour + 1
                    } else {
                        if (showScopeOneActivityRows && showScopeTwoActivityRows) {
                            scope3ActivityStartRow += startRowOfPartFour + 1 +
                                ghg[LABEL.scopeOneActivityRows].length + 2 +
                                ghg[LABEL.scopeTwoActivityRows].length + 2
                        } else if (showScopeOneActivityRows) {
                            scope3ActivityStartRow += startRowOfPartFour + 1 +
                                ghg[LABEL.scopeOneActivityRows].length + 2
                        } else if (showScopeTwoActivityRows) {
                            scope3ActivityStartRow += startRowOfPartFour + 1 +
                                ghg[LABEL.scopeTwoActivityRows].length + 2
                        }
                    }
                    renderPartFourByScope({
                        scope: 3,
                        scopeActivityRows: ghg[LABEL.scopeThreeActivityRows],
                        firstHeaderStartRow: scope3ActivityStartRow,
                    })
                }

                for (let row = 1; row <= ws.rowCount; row++) {
                    const currentRow = ws.getRow(row)
                    currentRow.height = 24
                }

                // Create xlsx file
                const fileLabel = 'Consolidated Statement of Greenhouse Gas Emissions'
                const fileName = `${selectedYear} ${account.client_name} ${fileLabel}`
                wb.xlsx.writeBuffer().then(function (data) {
                    const blob = new Blob([data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    })
                    const url = window.URL.createObjectURL(blob)
                    const anchor = document.createElement("a")
                    anchor.href = url
                    anchor.download = `${fileName}.xlsx`
                    anchor.click()
                    window.URL.revokeObjectURL(url)
                })
            }
        } catch (error) {
            console.log(error, 'error')
        }
    }

    return (
        <div className="page-content" >
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">
                                {t('report.label1')}
                            </h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div
                                className="card-body"
                                id="reportBody"
                                style={{
                                    overflowX: "auto",
                                }}
                            >
                                <div className="row px-3">
                                    <div className="align-items-start d-flex justify-content-between mb-3 px-0 gap-3 flex-wrap">
                                        <div className="position-relative">
                                            {/* <h4
                                                className='fw-bold m-0'
                                                style={{ fontSize: '24px' }}
                                            > */}
                                                {/* {account.client_name} */}
                                            {/* </h4> */}
                                            {/* <p className='m-0'>{selectedYear}</p> */}
                                        </div>
                                        <div className="d-flex flex-wrap gap-2 align-items-baseline">
                                            <select
                                                style={{
                                                    width: "fit-content",
                                                    // TODO: Set up form-select padding at the app level instead
                                                    padding: '0.3rem 2.1rem 0.3rem 0.9rem',
                                                }}
                                                onChange={onSelectYear}
                                                value={selectedYear}
                                                id="company"
                                                name="company"
                                                className="form-select form-select-sm"
                                            >
                                                {optionYear.map(({ year }) => <option key={year} value={year}>{year}</option>)}
                                            </select>
                                            <DownloadDropdown
                                                onGenerateReport={onGenerateReport}
                                                onDownloadExcel={onGenerateReportInExcel}
                                            />
                                        </div>
                                    </div>
                                    {(
                                        !!ghg[LABEL.scopeOneRows] ||
                                        !!ghg[LABEL.scopeTwoRows] ||
                                        !!ghg[LABEL.scopeThreeRows] ||
                                        !!ghg[LABEL.biogenicRows]
                                    ) && (
                                            <div className="col-lg-12 overflow-auto p-0">
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                colSpan={9 + additionalGassesCount}
                                                                style={thTitleStyles}
                                                            >
                                                                {t('report.GHGInventorySummary')} {t('report.GHGBasedOn')} {t('report.GHGProtocol')}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            {tableHeader.map(({ key, label }) => {
                                                                return (
                                                                    <th
                                                                        key={key}
                                                                        colSpan={key === 1 ? 2 : 1}
                                                                        style={thSubtitleTextStyles}
                                                                    >
                                                                        {label}
                                                                    </th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>

                                                    <tbody className='no-border'>
                                                        <TRScope
                                                            scope={LABEL.scopeOneRows}
                                                            data={ghg[LABEL.scopeOneRows]}
                                                            additionalColumn={additionalGassesCount}
                                                        />
                                                        <TRScope
                                                            scope={LABEL.scopeTwoRows}
                                                            data={ghg[LABEL.scopeTwoRows]}
                                                            additionalColumn={additionalGassesCount}
                                                        />
                                                        {account['client_scope'] > 1 ? <TRScope
                                                            scope={LABEL.scopeThreeRows}
                                                            data={ghg[LABEL.scopeThreeRows]}
                                                            additionalColumn={additionalGassesCount}
                                                        /> : ""}
                                                        <TRScope
                                                            scope='Biogenic Emission'
                                                            data={ghg[LABEL.biogenicRows]}
                                                            hasTotal={false}
                                                            additionalColumn={additionalGassesCount}
                                                        />
                                                    </tbody>

                                                    <EmptyTableBody />

                                                    <tbody>
                                                        {[
                                                            {
                                                                th: <span>{t('report.totalEmissionScopeOneAndTwo')} (tCO<sub>2</sub>e)</span>,
                                                                td: toLocaleString(ghg[LABEL.scopeOneScopeTwoTotal], locales),
                                                            },
                                                            {
                                                                th: <span>{t('report.totalEmissionScopeOneTwoAndThree')} (tCO<sub>2</sub>e)</span>,
                                                                td: toLocaleString(ghg[LABEL.scopeOneScopeTwoScopeThreeTotal], locales),
                                                                hide: account['client_scope'] <= 1,
                                                            },
                                                            {
                                                                th: `${t('report.totalRevenue')} (${ghg.revenue.unit})`,
                                                                td: toLocaleString(ghg.revenue.total, locales),
                                                            },
                                                            {
                                                                th: <span>{t('report.GHGIntensityScopeOneAndTwo')} (tCO<sub>2</sub>e) / {t('report.revenue')} ({ghg.revenue.unit})</span>,
                                                                td: <span>{toLocaleString(ghg[LABEL.intensityPerTotalRevenue], locales)} tCO<sub>2</sub>e {t('report.per')} {ghg.revenue.unit}</span>,
                                                            },
                                                            {
                                                                th: <span>{t('report.GHGIntensityScopeOneTwoAndThree')} (tCO<sub>2</sub>e) / {t('report.revenue')} ({ghg.revenue.unit})</span>,
                                                                td: <span>{toLocaleString(ghg[LABEL.intensityThreeScopesPerTotalRevenue], locales)} tCO<sub>2</sub>e {t('report.per')} {ghg.revenue.unit}</span>,
                                                                hide: account['client_scope'] <= 1,
                                                            },
                                                            // TODO: Update unit of production
                                                            {
                                                                th: <span>{t('report.GHGIntensityScopeOneAndTwo')} (tCO<sub>2</sub>e) / {t('report.unitOfProduction')}</span>,
                                                                td: <span>{toLocaleString(ghg[LABEL.intensityPerProductionUnit], locales)} tCO<sub>2</sub>e {t('report.per')} {ghg.revenue.unit}</span>,
                                                            },
                                                            {
                                                                th: <span>{t('report.GHGIntensityScopeOneAndTwo')} (tCO<sub>2</sub>e) / {t('report.totalEnergy')} (GJ)</span>,
                                                                td: <span>{toLocaleString(ghg[LABEL.intensityPerTotalEnergy], locales)} tCO<sub>2</sub>e {t('report.per')} GJ</span>,
                                                            },
                                                            // TODO: Update GHG Intensity Scope 1 + 2 + 3 (tCO2e) / Total Energy (GJ)
                                                            // when data is available
                                                            {
                                                              th: <span>{t('report.GHGIntensityScopeOneTwoAndThree')} (tCO<sub>2</sub>e) / {t('report.totalEnergy')} (GJ)</span>,
                                                              td: <span>{toLocaleString(ghg[LABEL.intensityPerTotalEnergy], locales)} tCO<sub>2</sub>e {t('report.per')} GJ</span>,
                                                              hide: account['client_scope'] <= 1,
                                                            },
                                                        ].map(({ th, td, hide = false }, i) => {
                                                            if (hide) {
                                                                return null
                                                            } else {
                                                                return (
                                                                    <tr key={i}>
                                                                        <th
                                                                            colSpan={6}
                                                                        >
                                                                            {th}
                                                                        </th>
                                                                        <td
                                                                            colSpan={3 + additionalGassesCount}
                                                                            style={{
                                                                                textAlign: 'right',
                                                                            }}
                                                                        >
                                                                            {td}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        })}
                                                    </tbody>

                                                    <EmptyTableBody />

                                                    <thead>
                                                        <tr>
                                                            <th
                                                                colSpan={9 + additionalGassesCount}
                                                                style={thSubtitleStyles}
                                                            >
                                                                {t('report.totalEmissionFromEnergy')}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <TRActivity
                                                            scope={LABEL.scopeOneRows}
                                                            rows={ghg?.[LABEL.scopeOneActivityRows]}
                                                            additionalColumn={additionalGassesCount}
                                                        />
                                                        <TRActivity
                                                            scope={LABEL.scopeTwoRows}
                                                            rows={ghg?.[LABEL.scopeTwoActivityRows]}
                                                            additionalColumn={additionalGassesCount}
                                                        />
                                                        {/* TODO: Show the component below if scope 3 energy calculation is available */}
                                                        {account['client_scope'] > 1 ? <TRActivity
                                                            scope={LABEL.scopeThreeRows}
                                                            rows={ghg?.[LABEL.scopeThreeActivityRows]}
                                                            additionalColumn={additionalGassesCount}
                                                        /> : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ReportGHGInventory
