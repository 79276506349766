import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import axios from "axios"
import qs from 'qs'
import Swal from 'sweetalert2'
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import {
  MRT_Localization_ID,
} from 'material-react-table/locales/id'
import {
  MRT_Localization_EN,
} from 'material-react-table/locales/en'

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import {
  ROUTE_PATHS,
  twoDecimalFormat,
  dateSortingFn,
} from '../../../library/helper'

import DateCell from '../../../components/DateCell'

const useTableLookup = () => {
  return useQuery({
    queryKey: [
      'business-travel-emission-record-summary-lookup',
    ],
    retry: false,
    queryFn: ({ signal }) => axios({
      url: [
        process.env.REACT_APP_BASE_SERVER,
        '/business-travel-emission-record-summary-lookup',
      ].join(''),
      method: 'GET',
      withCredentials: true,
      signal,
    }).then(response => {
      return response?.data?.data ?? []
    }),
  })
}

const useTable = (location) => {
  const { t, i18n } = useTranslation()

  const {
    data = [],
    isLoading,
    isRefetching,
  } = useTableLookup(location)

  const onDeleteCall = async (data) => {
    const options = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/emission/delete`
    };
    const response = await axios(options);
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  }

  const onDelete = async (id) => {
    let invalidData = true;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          'id': id,
        };
        const result = await onDeleteCall(data)
        if (result) {
          invalidData = false;
        }

        if (invalidData) {
          Swal.fire({
            title: '',
            html: '<p>Cannot delete data, please try again later</p>',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
        } else {
          Swal.fire({
            title: 'Deleted',
            text: "Your data has been deleted.",
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          })
        }
      }
    })
  }

  return useMaterialReactTable({
    enableBottomToolbar: true,
    enableColumnOrdering: true,
    enableDensityToggle: false,
    enableExpandAll: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: true,
    enablePagination: true,
    enableTableFooter: false,
    enableTopToolbar: true,
    initialState: { density: 'compact' },
    localization: i18n.language === 'id' ? (
      MRT_Localization_ID
    ) : MRT_Localization_EN,
    muiTablePaperProps: {
      elevation: 0,
    },
    columns: [
      {
        header: t('emission.table.label1'),
        size: 100,
        accessorKey: 'record_date',
        // Transform data before processing so sorting works
        accessorFn: (row) => new Date(row.record_date),
        Cell: ({ row }) => <DateCell dateTime={row.original.record_date} />,
        sortingFn: dateSortingFn,
      },
      {
        accessorKey: 'office_name',
        header: t('emission.table.label2'),
        size: 100,
        Cell: ({ cell }) => cell.getValue(),
      },
      {
        accessorKey: 'company_name',
        header: t('emission.table.company'),
        size: 100,
        Cell: ({ cell }) => cell.getValue(),
      },
      {
        accessorKey: 'emission_name',
        header: t('emission.table.source'),
        size: 100,
        Cell: ({ row }) => {
          const transports = []
          if (row.original.emission_name) {
            transports.push({
              name: row.original.emission_name,
            })
          }
          if (
            row.original.emission_name_2 &&
            row.original.record_amount_4
          ) {
            transports.push({
              name: row.original.emission_name_2,
            })
          }
          return (
            <div className="d-flex flex-wrap gap-2 fs-16">
              {
                transports.length > 0 && transports.map(transport => {
                  return <div
                    key={transport.name}
                    className="badge fw-medium badge-soft-secondary"
                  >
                    {transport.name}
                  </div>
                })
              }
            </div>
          )
        },
      },
      // {
      //   accessorKey: 'record_assets',
      //   header: t('emission.table.activity'),
      //   size: 100,
      // },
      {
        accessorKey: 'record_total',
        header: t('emission.table.label7'),
        size: 100,
        Cell: ({ cell }) => <div className="d-flex flex-column flex-wrap gap-2">
          <span>
            {twoDecimalFormat(cell.getValue(), i18n.language)} tCO<sub>2</sub>e
          </span>
        </div>,
      },
      {
        accessorKey: 'record_uuid',
        header: t('emission.table.label6'), // Action column
        size: 100,
        enableSorting: false,
        enableColumnFilter: false,
        enableGrouping: false,
        Cell: ({ cell }) => <div className="d-flex flex-wrap gap-2">
          <Link
            to={'/scope3/category6/update/' + cell.getValue()}
            className="btn btn-info btn-icon waves-effect waves-light"
          >
            <i className="ri-pencil-fill"></i>
          </Link>
          <button
            onClick={() => onDelete(cell.getValue())}
            type="button"
            className="btn btn-warning btn-icon waves-effect waves-light"
          >
            <i className="ri-delete-bin-2-fill"></i>
          </button>
        </div>,
      },
    ],
    data,
    state: {
      isLoading: isLoading || isRefetching,
    }
  })
}

export default function EmissionScope3Category6() {
  const account = useSelector((state) => state.account.value)
  const { t } = useTranslation()

  const table = useTable()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="page-content">
        <div className="container-fluid">

          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">
                  {t('menu.scope3Category6Title')}
                </h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0">
                    {t('general.read')}
                  </h5>
                  <br />
                  <div className="d-flex flex-wrap gap-2">
                    {account['client_subscription'] > 1 && (
                      <Link
                        to={ROUTE_PATHS.scope3category6Create}
                        className="btn btn-success"
                      >
                        {t('general.btnCreateInBulk')}
                      </Link>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <MaterialReactTable table={table} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </LocalizationProvider>
  )
}
